import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../helpers/product";
import ProductImageGallery from "../../components/product/ProductImageGallery";
import ProductDescriptionInfo from "../../components/product/ProductDescriptionInfo";
import ProductImageGallerySideThumb from "../../components/product/ProductImageGallerySideThumb";
import ProductImageFixed from "../../components/product/ProductImageFixed";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import StickyBox from "react-sticky-box";
import Card from "react-bootstrap/Card";
import ProductDescriptionTab from "./ProductDescriptionTab";
import VenueRightHalf from "../../components/product/VenueRightHalf";
import VenueLeftHalf from "../../components/product/VenueLeftHalf";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const ProductImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  galleryType,
  product,
  currency,
  cartItems,
  wishlistItems,
  compareItems,
}) => {
  const wishlistItem = wishlistItems.filter(
    (wishlistItem) => wishlistItem.id === product.id
  )[0];
  const compareItem = compareItems.filter(
    (compareItem) => compareItem.id === product.id
  )[0];
  const { addToast } = useToasts();
  //console.log("Gallery Type: ", galleryType);
  const discountedPrice = getDiscountPrice(product.topBid, product.discount);
  let finalProductPrice = +(product.topBid * currency.currencyRate).toFixed(2);
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(2);
  finalProductPrice = 1500;

  console.log("product is " + product)

  return (
    
    <div
      className='redToBlueGradient'
    >

<BreadcrumbsItem to={process.env.PUBLIC_URL + "/sala-los-alamos"}>Sala Los Alamos</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/sala-los-alamos/screening/" + product.id}>
        SCREENING
      </BreadcrumbsItem>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3">

          </div>
          <div className="col-lg-6 col-md-6">
          <h2 style={{display: 'flex', whitespace: 'normal', lineHeight: "120%", fontSize:'50px', textAlign: 'center',  marginBottom: '20px'}}>{product.name}</h2>
        <h3 style={{display: 'flex', whitespace: 'normal', lineHeight: "120%", fontSize:'15px', textAlign: 'center', marginBottom: '40px'}}>{`You will receive a free pass for every ${product.minBack} of your backing. Your card will only be charged if the threshold is met.`}</h3>
          </div>

        <div className="col-lg-3 col-md-3">

</div>
        </div>
        <div className="row">
       
  
          <div className="col-lg-8 col-md-8">
            {product && product.image && (
              <StickyBox
                offsetTop={20}
                offsetBottom={20}
                style={{ padding: "0 20px", marginBottom: "50px" }}
              >

              <VenueLeftHalf
                  product={product}
                  discountedPrice={discountedPrice}
                  currency={currency}
                  finalDiscountedPrice={finalDiscountedPrice}
                  finalProductPrice={finalProductPrice}
                  cartItems={cartItems}
                  wishlistItem={wishlistItem}
                  compareItem={compareItem}
                  addToast={addToast}
                />
              </StickyBox>
            )}
          </div>
          <div className="col-lg-4 col-md-4">
            {/* product description info */}
            <VenueRightHalf
              product={product}
              discountedPrice={discountedPrice}
              currency={currency}
              finalDiscountedPrice={finalDiscountedPrice}
              finalProductPrice={finalProductPrice}
              cartItems={cartItems}
              wishlistItem={wishlistItem}
              compareItem={compareItem}
              addToast={addToast}
            />

          </div>
        </div>
      </div>
    </div>
  );
};

ProductImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData,
  };
};

export default connect(mapStateToProps)(ProductImageDescription);
