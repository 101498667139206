import PropTypes from "prop-types";
import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

const ProductDescriptionTab = ({ spaceBottomClass, productFullDesc, biddingHistoryField, tabTitle }) => {

  
  return (
    <div className={`description-review-area ${spaceBottomClass} redToBlueGradient`}>
      <div className="container">
        <div className="description-review-wrapper">
          <Tab.Container defaultActiveKey="productDescription">
            <Nav variant="pills" className="description-review-topbar">
              
              <Nav.Item>
                <Nav.Link eventKey="productDescription">Event Description</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="biddingHistory">Bidding History</Nav.Link>
              </Nav.Item>
              
            </Nav>
            <Tab.Content className="description-review-bottom">
              
              <Tab.Pane eventKey="productDescription">
            
                {
                
                productFullDesc.toString().split("___").map((e) => {
                  
                  return <p> {e} </p>
                })}
                  
    
              </Tab.Pane>

              <Tab.Pane eventKey="biddingHistory">


                {biddingHistoryField && biddingHistoryField.length > 0 && 
                
                Object.keys(biddingHistoryField).map((e) => {
                  const bidHistoryObject = biddingHistoryField[biddingHistoryField.length - 1 - e];
                  const date = bidHistoryObject.split("$")[0].substring(0, bidHistoryObject.split("$")[0].length - 2);
                  const finalDate = new Date(date).toLocaleString();
                  const price = '$' + bidHistoryObject.split("$")[1];
                  return <li> {finalDate + " : " + price} </li>
                })}
              </Tab.Pane>
              
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

ProductDescriptionTab.propTypes = {
  productFullDesc: PropTypes.string,
  spaceBottomClass: PropTypes.string
};

export default ProductDescriptionTab;
