import PropTypes from "prop-types";
import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import toast, { Toaster } from 'react-hot-toast';

const IconGroup = ({
  currency,
  cartData,
  wishlistData,
  compareData,
  deleteFromCart,
  iconWhiteClass
}) => {
  const firebaseConfig = {
    apiKey: "AIzaSyCdRsWDcgh_CZS9riaSbAxyqtgntLQhvds",
    authDomain: "idol-dc7f4.firebaseapp.com",
    projectId: "idol-dc7f4",
    storageBucket: "idol-dc7f4.appspot.com",
    messagingSenderId: "525804222981",
    appId: "1:525804222981:web:4fc8381004a9cf53e13a6c",
    measurementId: "G-B1YY7RNJN6",
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const [loggedIn, setLogin] = useState(0);
  
  function logOut(){
    //console.log("Signing out")
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      window.location.href="/my-account"
    }).catch((error) => {
      // An error happened.
      toast.error("There was a problem when signing out. You may have to try later.");
    });
  }
  
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      setLogin(1);
    } else {
      setLogin(0);
    }
  });

  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  

  //Two versions of the login dropdown—one for signed in users and one for not
  function guestLoginDropdown(props) {
    return (<ul>
    <li>
      <Link to={process.env.PUBLIC_URL + "/login-register"}>Login</Link>
    </li>
    <li>
      <Link to={process.env.PUBLIC_URL + "/login-register"}>
        Register
      </Link>
    </li>
  </ul>
  
  );
  }
  //Remove the option to login and register if the user is already logged in. Instead, give the option to sign out.
  function loggedInDropdown(props) {
    return (<ul>
    
    <li>
      <Link to={process.env.PUBLIC_URL + "/my-account"}>
        my account
      </Link>
    </li>

    <li>
      <Link onClick={logOut}>
        Log Out
      </Link>
    </li>
  </ul>);
  }

  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      {/*<div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={e => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
  </div>*/}
      <div className="same-style account-setting d-none d-lg-block">
        <button
          className="account-setting-active"
          onClick={e => handleClick(e)}
        >
          <i className="pe-7s-user-female" />
        </button>
        <div className="account-dropdown">
          {loggedIn ? loggedInDropdown() : guestLoginDropdown()}
        </div>
      </div>
      {/*<div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareData && compareData.length ? compareData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div>*/}
      {/* <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={e => handleClick(e)}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </button>
        
        <MenuCart
          cartData={cartData}
          currency={currency}
          deleteFromCart={deleteFromCart}
        />
      </div> */}
      {/* <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </Link>
      </div>*/ 
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>}
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    compareData: state.compareData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
