import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import toast, { Toaster } from 'react-hot-toast';
const MobileNavMenu = ({ strings }) => {

  const firebaseConfig = {
    apiKey: "AIzaSyCdRsWDcgh_CZS9riaSbAxyqtgntLQhvds",
    authDomain: "idol-dc7f4.firebaseapp.com",
    projectId: "idol-dc7f4",
    storageBucket: "idol-dc7f4.appspot.com",
    messagingSenderId: "525804222981",
    appId: "1:525804222981:web:4fc8381004a9cf53e13a6c",
    measurementId: "G-B1YY7RNJN6",
  };
  const [user, setUser] = useState(null);
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  function logOut(){
    //console.log("Signing out")
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      window.location.href="/my-account"
    }).catch((error) => {
      // An error happened.
      toast.error("Error signing out");
    });
  }

  useEffect(() => {
    const auth = getAuth();
    if (auth.currentUser) {
      setUser(auth.currentUser);
    }
  },[])
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>{strings["home"]}</Link>
        </li>

        {/*<li>
                <Link to={process.env.PUBLIC_URL + "/about"}>
                  {"About"}
                </Link>
              </li>*/}

              <li>
                <Link to={process.env.PUBLIC_URL + "/fan-requests"}>
                  {"Fan Requests"}
                </Link>
              </li>

              <li>
                <Link to={process.env.PUBLIC_URL + "/sala-los-alamos"}>
                  {"Sala Los Alamos"}
                </Link>
              </li>

              <li>
                <Link to={process.env.PUBLIC_URL + "/tedx-berkeley"}>
                  {"TEDxBerkeley"}
                </Link>
              </li>

              {/*<li>
                <Link to={process.env.PUBLIC_URL + "/talent"}>
                  {"For Talent"}
                </Link>
  </li>*/}



    {user &&
    <li>
      <Link to={"/my-account"}>
        my account
      </Link>
      
    </li>}
    {user === null &&<> <li><Link to={"/login-register"}>Login</Link></li> 
    <li><Link to={"/login-register"}>Register</Link></li> </>}
    {user &&
    <Link onClick={logOut}>
        Logout
      </Link>}
    <li>

    </li>
      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object,
};

export default multilanguage(MobileNavMenu);