import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { css } from "styled-components/macro"; //eslint-disable-line

import "aos/dist/aos.css";
import AOS from "aos";
import EditTalentScreening from "./pages/shop-product/EditTalentScreening";

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-220139431-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);




// home pages
const Home = lazy(() => import("./pages/home/Home"));
const HomeSala = lazy(() => import("./pages/home/HomeSala"));
const HomeTed = lazy(() => import("./pages/home/HomeTed"));
const admin = lazy(() => import("./pages/home/admin"));
const HomeSalaEdit = lazy(() => import("./pages/home/HomeSalaEdit"));
const WordCloud = lazy(() => import("./pages/home/WordCloud"));
const FanRequestsCloud = lazy(() => import("./pages/home/FanRequestsCloud"));
const CarouselHome = lazy(() => import("./pages/home/CarouselHome"));
const CarouselHomeNew = lazy(() => import("./pages/home/CarouselHomeNew"));
const Sales = lazy(() => import("./pages/other/Sales"));
const BasicDark = lazy(() => import("./IdolAI/src/screens/BasicDark"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const VenueScreening = lazy(() => import("./pages/shop-product/VenueScreening"));
const TedSingleAuction = lazy(() => import("./pages/shop-product/TedSingleAuction"));
const Concert = lazy(() => import("./pages/shop-product/Concert"));
const EditVenueScreening = lazy(() => import("./pages/shop-product/EditVenueScreening"));
const VenueTalent = lazy(() => import("./pages/shop-product/VenueTalent"));
const Events = lazy(() => import("./pages/shop-product/Event"));
const Request = lazy(() => import("./pages/shop-product/Request"));
const RequestAthletesFirst = lazy(() =>
  import("./pages/shop-product/RequestAthletesFirst")
);
const DynamicFanRequests = lazy(() =>
  import("./pages/shop-product/DynamicFanRequests")
);
const RequestMaleAlpha = lazy(() =>
  import("./pages/shop-product/RequestMaleAlpha")
);
const RequestFormalEdit = lazy(() =>
  import("./pages/shop-product/RequestFormalEdit.js")
);
const RequestAddictedToBlack = lazy(() =>
  import("./pages/shop-product/RequestAddictedToBlack.js")
);
const RequestTVActor = lazy(() =>
  import("./pages/shop-product/RequestTVActor.js")
);
const RequestOFDemo = lazy(() =>
  import("./pages/shop-product/RequestOFDemo.js")
);
const RequestCelebConnect = lazy(() =>
  import("./pages/shop-product/RequestCelebConnect.js")
);
const RequestWMEAgency = lazy(() =>
  import("./pages/shop-product/RequestWMEAgency.js")
);
const RequestOITNB = lazy(() => import("./pages/shop-product/RequestOITNB.js"));
const RequestParadigmSports = lazy(() =>
  import("./pages/shop-product/RequestParadigmSports.js")
);
const RequestCarlo = lazy(() => import("./pages/shop-product/RequestCarlo.js"));
const RequestPortal = lazy(() => import("./pages/shop-product/RequestPortal"));
const DynamicIdolPortal = lazy(() =>
  import("./pages/shop-product/DynamicIdolPortal")
);
const AcceptForm = lazy(() =>
  import("./pages/shop-product/RequestPortalAccept")
);
const RespondForm = lazy(() =>
  import("./pages/shop-product/RequestPortalRespond")
);
const RejectForm = lazy(() =>
  import("./pages/shop-product/RequestPortalReject")
);
const RequestScott = lazy(() => import("./pages/shop-product/RequestScott.js"));
const TwitchWidget = lazy(() => import("./pages/shop-product/TwitchWidget"));

const Private = lazy(() => import("./pages/shop-product/Private"));
// other pages
const About = lazy(() => import("./pages/other/About"));
const FAQ = lazy(() => import("./pages/other/FAQ"));
const FanScheduleCall = lazy(() =>
  import("./pages/VideoCalling/FanScheduleCall")
);
const InfluencerSchedule = lazy(() =>
  import("./pages/VideoCalling/InfluencerSchedule")
);
const CallPrebuilt = lazy(() => import("./pages/VideoCalling/CallPrebuilt"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const StripeSuccess = lazy(() => import("./pages/shop-product/StripeSuccess"));
const StripeSuccessTedx = lazy(() => import("./pages/shop-product/StripeSuccessTedx"));
const SalaSuccess = lazy(() => import("./pages/shop-product/SalaSuccess"));
const StripeSuccessRequests = lazy(() =>
  import("./pages/shop-product/StripeSuccessRequests")
);

const NotFound = lazy(() => import("./pages/other/NotFound"));
const Aea = lazy(() => import("./pages/other/Aea"));
const parentPayment = lazy(() => import("./components/payments/parentPayment"));

const FanTerms = lazy(() => import("./pages/Terms/FanTerms"));
const InfluencerTerms = lazy(() => import("./pages/Terms/InfluencerTerms"));
const EventForm = lazy(() => import("./pages/celeb/EventForm"));
const ProposeRequestPortalForm = lazy(() =>
  import("./pages/celeb/ProposeRequestPortalForm")
);
const EventFormSuccess = lazy(() => import("./pages/celeb/EventFormSuccess"));
const RequestFormSuccess = lazy(() =>
  import("./pages/celeb/RequestFormSuccess")
);

const App = (props) => {
  /*
  Not sure why this doesn't work:
  
  const location = useLocation();
  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change
  */

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
        },
      })
    );
  });

  useEffect(() => {
    AOS.init({
      once: true,
      // disable: 'phone',
      duration: 700,
      easing: "ease-out-cubic",
    });
  });

  // Our login logic, fairly self explanatory. If the user is logged in, we mark Login as 1.
  const firebaseConfig = {
    apiKey: "AIzaSyCdRsWDcgh_CZS9riaSbAxyqtgntLQhvds",
    authDomain: "idol-dc7f4.firebaseapp.com",
    projectId: "idol-dc7f4",
    storageBucket: "idol-dc7f4.appspot.com",
    messagingSenderId: "525804222981",
    appId: "1:525804222981:web:4fc8381004a9cf53e13a6c",
    measurementId: "G-B1YY7RNJN6",
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const [loggedIn, setLogin] = useState(0);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      setLogin(1);
    } else {
      setLogin(0);
    }
  });

  return (
    <ToastProvider>
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="idol-preloader-wrapper">
                  <div className="idol-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              {/* The brains of the app, here are all of the routes. To explore a specific page, hover your mouse 
            over the corresponding component, and Command Click*/}
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={WordCloud}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/fan-requests"}
                  component={FanRequestsCloud}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/talent"}
                  component={Home}
                />

<Route
                  exact
                  path={process.env.PUBLIC_URL + "/sala-los-alamos"}
                  component={HomeSala}
                />

<Route
                  exact
                  path={process.env.PUBLIC_URL + "/tedx-berkeley"}
                  component={HomeTed}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/sala-los-alamos/admin"}
                  component={admin}
                />

<Route
                  exact
                  path={process.env.PUBLIC_URL + "/sala-los-alamos/edit"}
                  component={HomeSalaEdit}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/new_home"}
                  component={CarouselHome}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/ai"}
                  component={BasicDark}
                />

                {/** We provide this route to the influencer/celebrity after automatically creating a call through the scheduling tool. */}
                <Route
                  path={process.env.PUBLIC_URL + "/call/:id"}
                  render={(routeProps) => (
                    <CallPrebuilt
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />
                {/**THE :id variable is the influencer user id */}
                <Route
                  path={process.env.PUBLIC_URL + "/influencer/schedule/:id"}
                  render={(routeProps) => (
                    <InfluencerSchedule
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                {/** THE :id variable is the event id and is only accessible by one of the participants */}
                <Route
                  path={process.env.PUBLIC_URL + "/event/schedule-call/:id"}
                  render={(routeProps) => (
                    <FanScheduleCall
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />
                {/* The main event page for a particular auction */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/concert/:id"}
                  render={(routeProps) => (
                    <Concert {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/sala-los-alamos/screening/:id"}
                  render={(routeProps) => (
                    <VenueScreening {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

<Route
                  path={process.env.PUBLIC_URL + "/tedx-berkeley/auction/:id"}
                  render={(routeProps) => (
                    <TedSingleAuction {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/edit/sala-los-alamos/screening/:id"}
                  render={(routeProps) => (
                    <EditVenueScreening {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/edit/sala-los-alamos/talent/:id"}
                  render={(routeProps) => (
                    <EditTalentScreening {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/sala-los-alamos/talent/:id"}
                  render={(routeProps) => (
                    <VenueTalent {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/events/:id"}
                  render={(routeProps) => (
                    <Events {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/request-demo/funfitt"}
                  render={(routeProps) => (
                    <Request {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/request-portal-demo/funfitt"}
                  render={(routeProps) => (
                    <RequestPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/request-demo/athletes-first"}
                  render={(routeProps) => (
                    <RequestAthletesFirst
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/idol/:id"}
                  render={(routeProps) => (
                    <DynamicFanRequests
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL + "/request-demo/become-alpha-male"
                  }
                  render={(routeProps) => (
                    <RequestMaleAlpha
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL + "/request-demo/the-formal-edit"
                  }
                  render={(routeProps) => (
                    <RequestFormalEdit
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL + "/request-demo/addicted-to-black"
                  }
                  render={(routeProps) => (
                    <RequestAddictedToBlack
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/request-demo/tv-actor"}
                  render={(routeProps) => (
                    <RequestTVActor
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL + "/request-demo/only-fans-model"
                  }
                  render={(routeProps) => (
                    <RequestOFDemo
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/request-demo/celeb-connect"}
                  render={(routeProps) => (
                    <RequestCelebConnect
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/request-demo/wme-agency"}
                  render={(routeProps) => (
                    <RequestWMEAgency
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/request-demo/oitnb"}
                  render={(routeProps) => (
                    <RequestOITNB
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL + "/request-demo/paradigm-sports"
                  }
                  render={(routeProps) => (
                    <RequestParadigmSports
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/request-demo/carlo-alberto-orrechia"
                  }
                  render={(routeProps) => (
                    <RequestCarlo
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/request-demo/scott-carlson"}
                  render={(routeProps) => (
                    <RequestScott
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL + "/request-portal-demo/wme-agency"
                  }
                  render={(routeProps) => (
                    <RequestPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/idol-portal/:id"}
                  render={(routeProps) => (
                    <DynamicIdolPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                {/*<Route path={process.env.PUBLIC_URL + "/idol-portal/:id/:requestId/accept"}
                        render={(routeProps) => (
                          <AcceptForm {...routeProps} key={routeProps.match.params.id + "/" + routeProps.match.params.requestId + "/accept"} />
                        )} />*/}

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/idol-portal/:id/:requestId/respond"
                  }
                  render={(routeProps) => (
                    <RespondForm
                      {...routeProps}
                      key={
                        routeProps.match.params.id +
                        "/" +
                        routeProps.match.params.requestId +
                        "/respond"
                      }
                    />
                  )}
                />

                {/*<Route path={process.env.PUBLIC_URL + "/idol-portal/:id/:requestId/reject"}
                        render={(routeProps) => (
                          <RejectForm {...routeProps} key={routeProps.match.params.id + "/" + routeProps.match.params.requestId + "/reject"} />
                        )} />*/}

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/request-portal-demo/celeb-connect"
                  }
                  render={(routeProps) => (
                    <RequestPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/request-portal-demo/only-fans-model"
                  }
                  render={(routeProps) => (
                    <RequestOFDemo
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/request-portal-demo/the-formal-edit"
                  }
                  render={(routeProps) => (
                    <RequestPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/request-portal-demo/oitnb"}
                  render={(routeProps) => (
                    <RequestPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/request-portal-demo/paradigm-sports"
                  }
                  render={(routeProps) => (
                    <RequestPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/request-portal-demo/carlo-alberto-orrechia"
                  }
                  render={(routeProps) => (
                    <RequestPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/request-portal-demo/scott-carlson"
                  }
                  render={(routeProps) => (
                    <RequestScott
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL + "/request-portal-demo/tv-actor"
                  }
                  render={(routeProps) => (
                    <RequestPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/request-portal-demo/addicted-to-black"
                  }
                  render={(routeProps) => (
                    <RequestPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/request-portal-demo/become-alpha-male"
                  }
                  render={(routeProps) => (
                    <RequestPortal
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL + "/private/ZW1pbHkgY29jZWEK/:id"
                  }
                  render={(routeProps) => (
                    <Private {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/request-success/:id"}
                  render={(routeProps) => (
                    <RequestFormSuccess
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/private/:id"}
                  render={(routeProps) => (
                    <Private {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/preview/:id"}
                  render={(routeProps) => (
                    <Private {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/widgets/:eventType/:id"}
                  render={(routeProps) => (
                    <TwitchWidget
                      {...routeProps}
                      key={
                        routeProps.match.params.id +
                        "/" +
                        routeProps.match.params.id
                      }
                    />
                  )}
                />

                {/** Payment related Pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/bid/success"}
                  component={StripeSuccess}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/bid_tedx/success"}
                  component={StripeSuccessTedx}
                />
                 <Route
                  path={process.env.PUBLIC_URL + "/sala-los-alamos/success"}
                  component={SalaSuccess}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/fanrequest/success"}
                  component={StripeSuccessRequests}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/payment"}
                  component={parentPayment}
                />

                {/** Celeb Application Pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/auction"}
                  component={EventForm}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/enroll"}
                  component={ProposeRequestPortalForm}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/auction-success"}
                  component={EventFormSuccess}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/fan-terms"}
                  component={FanTerms}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/influencer-terms"}
                  component={InfluencerTerms}
                />

                {/** Miscellaneous  Pages */}
                {/*<Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route path={process.env.PUBLIC_URL + "/faq"} component={FAQ} />*/}

                <Route
                  path={process.env.PUBLIC_URL + "/sales"}
                  component={Sales}
                />
                {/*<Route
                  path={process.env.PUBLIC_URL + "/talent"}
                  component={Sales}
                  />*/}

                {/** Auth related Pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/forgot-password"}
                  component={ForgotPassword}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/aea"}
                  component={Aea}
                  />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
