import React from "react";
import { Row, Container } from "react-bootstrap";

function HeroBanner() {
  return (
    <section id="home">
      <Container>
        <Row>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-6">
            <div className="section-text__subtitle">Collab and interact with talent.</div>
            <div className="section-text__title-big">
            Personalized requests to world-famous talent
            </div>
            <span> </span>
            <div className="section-text__body">
              Got talent? Join <a href="/talent"> <b>here</b></a>.
            </div>
            <span> </span>
          </div>

          <div
            className="col-xl-3 col-lg-4 col-md-4 col-11 col-sm-5"
            style={{ justifyContent: "center" }}
          >
            <img
              src={require("./video.png")}
              alt="Video Call Logo"
              width="110%"
              resizeMode={"contain"}
            />
            <br></br>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default HeroBanner;
