import PropTypes from "prop-types";
import React, { useEffect,Fragment } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import RelatedProductSlider from "../../wrappers/product/RelatedProductSlider";
import ProductDescriptionTab from "../../wrappers/product/ProductDescriptionTab";
import VenueScreeningImageDescription from "../../wrappers/product/VenueScreeningImageDescription";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import styled from "styled-components";
import HeaderOne from "../../wrappers/header/HeaderOne";
import { Tab, Nav, FormLabel } from "react-bootstrap";
import { getDoc } from "firebase/firestore";
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import tw from "twin.macro";
import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import toast, { Toaster } from 'react-hot-toast';
const firebaseConfig = {
  apiKey: "AIzaSyCdRsWDcgh_CZS9riaSbAxyqtgntLQhvds",
  authDomain: "idol-dc7f4.firebaseapp.com",
  projectId: "idol-dc7f4",
  storageBucket: "idol-dc7f4.appspot.com",
  messagingSenderId: "525804222981",
  appId: "1:525804222981:web:4fc8381004a9cf53e13a6c",
  measurementId: "G-B1YY7RNJN6"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`border hover:border-black`;  
const TextArea = tw.textarea`bg-transparent border-dotted rounded-lg resize-none`;
const Button = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-green-500 text-gray-100 w-full py-4 rounded-lg hover:bg-green-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Product = ({ location, product }) => {
  const [loadedProduct, setProduct] = useState({});
  const [acceptingBlurb, setAcceptingBlurb] = useState("");
  const [metadata, setMetadata] = useState({});
  const [MetadataString, setMetadataString] = useState("");
  const [minBack, setMinback] = useState("");
  const [miniBlurb, setMiniblurb] = useState("");
  const [name, setName] = useState("");
  const [topBid, setTopbid] = useState("");
  const [threshold, setThreshold] = useState(0);
  const [bullets, setBullets] = useState("");
  const [loadedDoc, setDoc] = useState({});
  const { pathname } = location;
  let productID = pathname.split('/')[4]

  useEffect(async () => {
  const celebrities = collection(db, "los_alamos_talent");
  const q = query(celebrities, where("id", "==", productID));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    setProduct(doc);    
    setMinback(doc.data().minBack);
    setMetadata(doc.data().metadata);
    setMetadataString(JSON.stringify(doc.data().metadata, null, 2));
    setMiniblurb(doc.data().miniBlurb);
    setAcceptingBlurb(doc.data().acceptingBlurb);
    setName(doc.data().name);
    setTopbid(doc.data().topBid);
    setThreshold(doc.data().threshold);
    setBullets(doc.data().bullets.join("\n")) 
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    var obj = new Object();
    obj.productID = productID;
    obj.miniBlurb = miniBlurb;
    obj.acceptingBlurb = acceptingBlurb;
    obj.name = name;
    obj.threshold = threshold;
    obj.bullets = bullets;
    obj.minBack = minBack;
    await fetch(`/api/edit-sala-event`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then((ret) => {
        // console.log("Got back data");
        setMetadata(ret.metadata);
        setMiniblurb(ret.miniBlurb);
        setMinback(ret.minBack)
        setAcceptingBlurb(ret.acceptingBlurb);
        setName(ret.name);
        setThreshold(ret.threshold);
        setBullets(ret.bullets);
        toast.success("Successfully edited! View the event page from the fan perspective to see updates :)")
      })
      .catch((error) => {
        // console.error(error);
        toast.error(error);
      });
  }
  


  return (
    <Fragment className="redToBlueGradient">
<div>
        <Toaster />
      </div>
<HeaderOne
        layout="container-fluid"
        headerPaddingClass="header-padding-1"
      />


       

        {/* breadcrumb */}
        <BreadcrumbsItem to={process.env.PUBLIC_URL + "/sala-los-alamos/edit"}>Sala Los Alamos</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/edit/sala-los-alamos/screening/" + (loadedProduct ? loadedProduct.id : '')}>
        SCREENING
      </BreadcrumbsItem>

      <div className="redToBlueGradient">
        <div className="row">
          <div className="col-lg-6 col-md-12 ml-auto mr-auto">
            <div className="login-register-wrapper">
              <div className="section-text__title-big text-center">
                Edit <a target="_blank" href={process.env.PUBLIC_URL + "/sala-los-alamos/screening/" + loadedProduct.id}><u>{Object.keys(loadedProduct).length > 0 && loadedProduct.data().name}</u></a>
              </div>


        {Object.keys(loadedProduct).length > 0 && 

         (<FormContainer className="login-form-container">
                        <div className="login-register-form">
                          <Form onSubmit={handleSubmit}>
                            <FormLabel>Accepting Blurb</FormLabel>
                            <br/>
                            <Input
                              type="text"
                              key="accepting_blurb"
                              required
                              name="accepting-blurb"
                              value={acceptingBlurb}
                              onChange={(e) => setAcceptingBlurb(e.target.value)}
                            />

                            <FormLabel>Amount to Receive a Free Pass</FormLabel>
                            <br/>
                            <Input
                              type="text"
                              required
                              name="min-back"
                              value={minBack}
                              onChange={(e) => setMinback(e.target.value)}
                            />

                            <FormLabel>Threshold Number of Tickets</FormLabel>
                            <br/>
                            <Input
                              type="text"
                              required
                              name="threshold"
                              value={threshold}
                              onChange={(e) => setThreshold(e.target.value)}
                            />

                            <FormLabel>Event Name</FormLabel>
                            <br/>
                            <TextArea
                              type="text"
                              required
                              name="event-name"
                              value={name}
                              rows={3}
                              className="bg-white border hover:border-black"
                              onChange={(e) => setName(e.target.value)}
                            />

                          <FormLabel>Blurb</FormLabel>
                          <p> Use the "___" delimiter for a line break. </p>
                            <br/>
                            <TextArea
                              type="text"
                              required
                              name="blurb"
                              value={miniBlurb}
                              className="bg-white border hover:border-black"
                              rows={10}
                              onChange={(e) => setMiniblurb(e.target.value)}
                            />


                      <FormLabel>Bullets</FormLabel>
                          <p> Each line will be a new bullet point. </p>
                            <br/>
                            <TextArea
                              type="text"
                              required
                              name="blurb"
                              value={bullets}
                              className="bg-white border hover:border-black"
                              rows={10}
                              onChange={(e) => setBullets(e.target.value)}
                            />
                          
                            <div className="button-box">
                              <Button type="submit">
                                <span>Submit</span>
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </FormContainer>)}
                      </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

Product.propTypes = {
  location: PropTypes.object,
  product: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  const itemId = ownProps.match.params.id;
  return {
    product: state.productData.products.filter(
      single => single.id === itemId
    )[0]
  };
};

export default connect(mapStateToProps)(Product);
