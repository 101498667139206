import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import { getProductCartQuantity } from "../../helpers/product";
import { QuantityPicker } from "react-qty-picker";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import Rating from "./sub-components/ProductRating";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import {doc, onSnapshot, getFirestore } from "firebase/firestore";
import { checkHasCard } from "../../actions/auth.js";
import CurrencyInput from "react-currency-input-field";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import toast, { Toaster } from "react-hot-toast";
import {
  getDoc,
} from "firebase/firestore";
import pulse from "./pulse.png"

const firebaseConfig = {
  apiKey: "AIzaSyCdRsWDcgh_CZS9riaSbAxyqtgntLQhvds",
  authDomain: "idol-dc7f4.firebaseapp.com",
  projectId: "idol-dc7f4",
  storageBucket: "idol-dc7f4.appspot.com",
  messagingSenderId: "525804222981",
  appId: "1:525804222981:web:4fc8381004a9cf53e13a6c",
  measurementId: "G-B1YY7RNJN6",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();

const ProductDescriptionInfo = ({
  product,
  discountedPrice,
  currency,
  finalDiscountedPrice,
  finalProductPrice,
  cartItems,
  wishlistItem,
  compareItem,
  addToast,
  addToCart,
  addToWishlist,
  addToCompare,
}) => {
  const [bid, setBid] = useState(0);
  const [login, setLogin] = useState(0);
  const [bidEnd, setBidEnd] = useState({ seconds: 100000000 });
  const [email, setEmail] = useState("start");
  const [customerEmail, setCustomerEmail] = useState("");
  const [cardDetails, setCardDetails] = useState(null);
  const [image, setImage] = useState("");
  const [celebName, setCelebName] = useState("");
  const [topBid, setTopBid] = useState("0.00");
  const [fullDescription, setFullDescription] = useState("");
  const [miniBlurb, setMiniBlurb] = useState("");
  const [youtube, setYoutube] = useState("");
  const [meta, setMeta] = useState({});

  const [quantity, setQuantity] = useState(1);
  const [selectedProductColor, setSelectedProductColor] = useState(
    product.variation ? product.variation[0].color : ""
  );

  const [userObj, setUserObj] = useState({
    customerId: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const [selectedProductSize, setSelectedProductSize] = useState(
    product.variation ? product.variation[0].size[0].name : ""
  );
  const [productStock, setProductStock] = useState(1);
  const [quantityCount, setQuantityCount] = useState(1);

  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
    selectedProductColor,
    selectedProductSize
  );

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;

      setEmail(user.email);
      setLogin(1);
    } else {
      setEmail("");
      setLogin(0);
    }
  });
  
  let url = window.location.href.split("/");
  const db = getFirestore();
    useEffect(() => {
    let eventId = (url[url.length - 1]);
    if (eventId.includes("?")) {
      eventId = eventId.split("?")[0];
    }
    if (eventId.includes("#")) {
      eventId = eventId.split("#")[0];
    }
    const bidChanged = onSnapshot(doc(db, "los_alamos", eventId), (doc) => {
      console.log("Current data: ", doc.data());
      if (doc.data().topBid != topBid) {
        setTopBid(doc.data().topBid);
        // toast.success("Top bid is $" +doc.data().topBid)
      }
  });
  }, []);



  const user = auth.currentUser;
  const [hasCard, setHasCard] = useState(false);
  useEffect(() => {
    if (email.length > 0 && email != "start") {
      var obj = new Object();
      obj.email = email;
      fetch(`/api/get-customer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(obj),
      })
        .then((res) => {
          if (res.ok) return res.json();
          return res.json().then((json) => Promise.reject(json));
        })
        .then((ret) => {
          ////console.log(ret);
          setUserObj(ret);
        })
        .catch((error) => {
          // console.error(error);
          toast.error(
            "Idol is having login issues at the moment. Contact contact@idolapp.io to bid."
          );
        });
    }
  }, [email]);

  var currentDateTime = new Date();


  useEffect(async () => {
    let url = window.location.href.split("/");
    var obj = new Object();
    obj.idolEventId = url[url.length - 1];
    if (obj.idolEventId.includes("?")) {
      obj.idolEventId = obj.idolEventId.split("?")[0];
    }
    if (obj.idolEventId.includes("#")) {
      obj.idolEventId = obj.idolEventId.split("#")[0];
    }
    await fetch(`/api/get-screening`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(obj),
      //mode: "no-cors",
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(
        ({ bidEnd, image, celebName, fullDescription, miniBlurb, topBid, youtube, meta }) => {
          setBidEnd(bidEnd);
          setImage(image);
          setTopBid(topBid);
          setCelebName(celebName);
          setFullDescription(fullDescription);
          setMiniBlurb(miniBlurb);
          setYoutube(youtube)
          console.log("My balls " +meta);
          setMeta(meta)
          
        }
      )
      .catch((error) => {
        // console.error(error);
        toast.error(error);
      });
  }, []);
  async function handleBid() {
    const productPrice = topBid;
    setBid(productPrice)
    //console.log(productPrice, bid);
    if (!login) {
      window.location.href = "/login-register";
    } else {
      var obj = new Object();
      obj.price = productPrice;
      obj.name = product.celebName;
      obj.qty = quantity;
      obj.description = product.fullDescription;
      obj.miniBlurb = product.miniBlurb;
      obj.bidEnd = product.bidEnd.seconds.toString();
      obj.email = email;
      obj.newBid = topBid;
      let url = window.location.href.split("/");
      obj.idolEventId = url[url.length - 1];
      if (obj.idolEventId.includes("?")) {
        obj.idolEventId = obj.idolEventId.split("?")[0];
      }
      if (obj.idolEventId.includes("#")) {
        obj.idolEventId = obj.idolEventId.split("#")[0];
      }
      obj.customerId = userObj.customerId;
      obj.private = false;

      await fetch(`/api/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(obj),
        //mode: "no-cors",
      })
        .then((res) => {
          if (res.ok) return res.json();
          return res.json().then((json) => Promise.reject(json));
        })
        .then(({ url }) => {
          //console.log("Tryign to redirect to checkout ", url);
          window.location.replace(url);
        })
        .catch((error) => {
          // console.error(error);
          toast.error(
            "Our payment provider Stripe is down at the moment. Contact contact@idolapp.io to reserve."
          );
        });
      //window.location.reload();*/
    }
  }

  return (
    <div className="product-details-content ml-70">

    
      <div>
          <LiteYouTubeEmbed 
              thumbnail={product.image[0]}
              id={youtube}
              title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
          />
        </div>

        <div className="pro-details-list">
        <div>
          <Toaster />
        </div>

        <p>{miniBlurb.toString().split("___").map((e) => {
                  
                  return <div> <p> {e} </p> <br/> </div>
                })}</p>


      <table>
        {meta && Object.entries(meta).map( ([key, value]) =>
          (<tr>
            <th>{key}</th>
            <td>{value}</td>
          </tr>
        ))}
      </table>

      </div>
    </div>
  );
};

ProductDescriptionInfo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  addToast: PropTypes.func,
  cartItems: PropTypes.array,
  compareItem: PropTypes.array,
  currency: PropTypes.object,
  discountedPrice: PropTypes.number,
  finalDiscountedPrice: PropTypes.number,
  finalProductPrice: PropTypes.number,
  product: PropTypes.object,
  wishlistItem: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    },
  };
};

export default connect(null, mapDispatchToProps)(ProductDescriptionInfo);