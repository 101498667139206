import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import { getProductCartQuantity } from "../../helpers/product";
import { QuantityPicker } from "react-qty-picker";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import Rating from "./sub-components/ProductRating";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import {doc, onSnapshot, getFirestore } from "firebase/firestore";
import { checkHasCard } from "../../actions/auth.js";
import CurrencyInput from "react-currency-input-field";
import toast, { Toaster } from "react-hot-toast";
// import the progress bar
import StepProgressBar from 'react-step-progress';
import { Steps } from 'rsuite';
import { RWebShare } from "react-web-share";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

// import the stylesheet
import 'react-step-progress/dist/index.css';
import "react-step-progress-bar/styles.css";


import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {
  getDoc,
} from "firebase/firestore";
import pulse from "./pulse.png"

const firebaseConfig = {
  apiKey: "AIzaSyCdRsWDcgh_CZS9riaSbAxyqtgntLQhvds",
  authDomain: "idol-dc7f4.firebaseapp.com",
  projectId: "idol-dc7f4",
  storageBucket: "idol-dc7f4.appspot.com",
  messagingSenderId: "525804222981",
  appId: "1:525804222981:web:4fc8381004a9cf53e13a6c",
  measurementId: "G-B1YY7RNJN6",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();

const ProductDescriptionInfo = ({
  product,
  discountedPrice,
  currency,
  finalDiscountedPrice,
  finalProductPrice,
  cartItems,
  wishlistItem,
  compareItem,
  addToast,
  addToCart,
  addToWishlist,
  addToCompare,
}) => {
  const [bid, setBid] = useState(0);
  const [login, setLogin] = useState(0);
  const [bidEnd, setBidEnd] = useState({ seconds: 100000000 });
  const [email, setEmail] = useState("start");
  const [customerEmail, setCustomerEmail] = useState("");
  const [cardDetails, setCardDetails] = useState(null);
  const [image, setImage] = useState("");
  const [celebName, setCelebName] = useState("");
  const [topBid, setTopBid] = useState("0.00");
  const [fullDescription, setFullDescription] = useState("");
  const [miniBlurb, setMiniBlurb] = useState("");
  const [offerPrice, setOfferPrice] = useState("$0");
  const [percent, setPercent] = useState(0);
  const [sponsorSalaRequest, setSponsorSalaRequest] = useState("");
  const [customRequest, setCustomRequest] = useState("");
  const [birthdayRequest, setBirthdayRequest] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedProductColor, setSelectedProductColor] = useState(
    product.variation ? product.variation[0].color : ""
  );

  const [userObj, setUserObj] = useState({
    customerId: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const [selectedProductSize, setSelectedProductSize] = useState(
    product.variation ? product.variation[0].size[0].name : ""
  );
  const [productStock, setProductStock] = useState(1);
  const [quantityCount, setQuantityCount] = useState(1);

  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
    selectedProductColor,
    selectedProductSize
  );

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;

      setEmail(user.email);
      setLogin(1);
    } else {
      setEmail("");
      setLogin(0);
    }
  });
  
  let url = window.location.href.split("/");
  const db = getFirestore();
    useEffect(() => {
    let eventId = (url[url.length - 1]);
    if (eventId.includes("?")) {
      eventId = eventId.split("?")[0];
    }
    if (eventId.includes("#")) {
      eventId = eventId.split("#")[0];
    }
    const bidChanged = onSnapshot(doc(db, "los_alamos", eventId), (doc) => {
      console.log("Current data: ", doc.data());
      if (doc.data().topBid != topBid) {
        setTopBid(doc.data().topBid);
        // toast.success("Top bid is $" +doc.data().topBid)
      }
  });
  }, []);


  const step1Content = <h1>Step 1 Content</h1>;
const step2Content = <h1>Step 2 Content</h1>;
const step3Content = <h1>Step 3 Content</h1>;

function StepComplete({accomplished}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{filter: `grayscale(${accomplished ? 0 : 80}%)`}}
    >
      <circle cx="12" cy="12" r="12" fill="#ED7D31" />
    </svg>
  );
}


  const user = auth.currentUser;
  const [hasCard, setHasCard] = useState(false);
  useEffect(() => {
    if (email.length > 0 && email != "start") {
      var obj = new Object();
      obj.email = email;
      fetch(`/api/get-customer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(obj),
      })
        .then((res) => {
          if (res.ok) return res.json();
          return res.json().then((json) => Promise.reject(json));
        })
        .then((ret) => {
          ////console.log(ret);
          setUserObj(ret);
        })
        .catch((error) => {
          // console.error(error);
          toast.error(
            "Idol is having login issues at the moment. Contact contact@idolapp.io to bid."
          );
        });
    }
  }, [email]);

  var currentDateTime = new Date();


  useEffect(async () => {
    let url = window.location.href.split("/");
    var obj = new Object();
    obj.idolEventId = url[url.length - 1];
    if (obj.idolEventId.includes("?")) {
      obj.idolEventId = obj.idolEventId.split("?")[0];
    }
    if (obj.idolEventId.includes("#")) {
      obj.idolEventId = obj.idolEventId.split("#")[0];
    }
    await fetch(`/api/get-screening`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(obj),
      //mode: "no-cors",
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(
        ({ bidEnd, image, celebName, fullDescription, miniBlurb, topBid, biddersLength, threshold }) => {
          setBidEnd(bidEnd);
          setImage(image);
          setTopBid(topBid);
          setCelebName(celebName);
          setFullDescription(fullDescription);
          setMiniBlurb(miniBlurb);
          setPercent((biddersLength / threshold) * 100)
          console.log('biddersLength' + biddersLength + ' threshold ' + threshold)
        }
      )
      .catch((error) => {
        // console.error(error);
        toast.error(error);
      });
  }, []);

  useEffect(async () => {
    var obj = new Object();
    
    await fetch(`/api/get-sala-home`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then((ret) => {
        // console.log("Got back data");
        setSponsorSalaRequest(ret.sponsorSalaRequest);
        setBirthdayRequest(ret.birthdayRequest);
        setCustomRequest(ret.customRequest);
      })
      .catch((error) => {
        // console.error(error);
        toast.error(error);
      });
  }, []);
  async function handleBid() {
    const productPrice = parseInt(offerPrice.replace( /^\D+/g, ''));
    setBid(productPrice)
    //console.log(productPrice, bid);
    if (!login) {
      window.location.href = "/login-register";
    } else {
      var obj = new Object();
      obj.price = productPrice;
      obj.name = product.celebName;
      obj.qty = Math.floor(parseInt(offerPrice.replace( /^\D+/g, '')) / topBid);
      obj.miniBlurb = product.miniBlurb;
      obj.email = email;
      let url = window.location.href.split("/");
      obj.idolEventId = url[url.length - 1];
      if (obj.idolEventId.includes("?")) {
        obj.idolEventId = obj.idolEventId.split("?")[0];
      }
      if (obj.idolEventId.includes("#")) {
        obj.idolEventId = obj.idolEventId.split("#")[0];
      }
      obj.customerId = userObj.customerId;

      await fetch(`/api/checkout-sala`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(obj),
        //mode: "no-cors",
      })
        .then((res) => {
          if (res.ok) return res.json();
          return res.json().then((json) => Promise.reject(json));
        })
        .then(({ url }) => {
          //console.log("Tryign to redirect to checkout ", url);
          window.location.replace(url);
        })
        .catch((error) => {
          // console.error(error);
          toast.error(
            "Our payment provider Stripe is down at the moment. Contact contact@idolapp.io to reserve."
          );
        });
      //window.location.reload();*/
    }
  }

  return (
    
    <div className="product-details-content ml-70">
      <div>
        <Toaster />
      </div>

      <div style={{display: "flex", alignItems: "flex-start", marginBottom: '20px'}}>
      
        <img src={pulse} style={{verticalAlign: "middle", marginTop: "15px", marginRight: "5px"}} width="20px"/>
        {product.acceptingBlurb && <b> <p style={{fontSize: "20px", marginTop: "12px", color: "#88beb0"}}>  {product.acceptingBlurb} </p></b>}
        </div>


        <ProgressBar
        percent={percent}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
        
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{position: "absolute"}}>

            <p style={{fontSize: "10px", lineHeight: 1, marginTop: "40px"}}> Getting Started! </p> 
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{position: "absolute"}}>

            <p style={{fontSize: "10px", lineHeight: 1, marginTop: "40px"}}> Heating Up! </p> 
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{position: "absolute"}}>

            <p style={{fontSize: "10px", lineHeight: 1, marginTop: "40px"}}> Almost There! </p> 
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{position: "absolute"}}>

            <p style={{fontSize: "10px", lineHeight: 1, marginTop: "40px"}}> Gonna Happen! </p> 
            </div>
          )}
        </Step>
        
      </ProgressBar>


      <div className="pro-details-quality">
        <CurrencyInput
          required
          id="input-example"
          name="input-name"
          placeholder="Offer (USD)"
          decimalsLimit={2}
          prefix="$"
          style={{borderRadius:"20px"}}
          onChange={(e) => setOfferPrice(e.target.value)}
          tw="mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500"
        />

        <div className="pro-details-cart btn-hover">
          <button onClick={async () => await handleBid()} style={{borderRadius:"20px"}}>
            {" "}
            Back This Event{" "}
          </button>
        </div>
      </div>

      { offerPrice != '$' && <i><p style={{color: "#88beb0"}}> Your offer would yield <u>{Math.floor(parseInt(offerPrice.replace( /^\D+/g, '')) / topBid)}</u> free passes </p></i> }
      <br></br>
    <RWebShare
        data={{
          text: celebName,
          url: window.location.href,
          title: "Share Idol Event",
        }}
        onClick={() => console.log("shared successfully!")}
      >

          <button style={{borderRadius:"20px", fontSize:'20px', color:"white", height:'40px', backgroundColor:"#04AA6D", textAlign: 'center', width: '100%'}}>
            Share
          </button>
</RWebShare>
<br></br>
<h6 style={{marginTop: 10, }}>Tell others you're backing this event!</h6>

  {console.log(offerPrice)}

 

      
{/*
      <div className="product-details-price">
        {discountedPrice !== null ? (
          <Fragment>
            <span>{currency.currencySymbol + finalDiscountedPrice}</span>{" "}
            <span className="old">{currency.currencySymbol + topBid}</span>
          </Fragment>
        ) : (
          <>

          {product.oldPrice && 

          <strike>
          <h2 style={{fontSize:'25px', color:'grey'}}>{currency.currencySymbol +
                parseFloat(product.oldPrice).toFixed(2).toString()} USD</h2></strike>}


                &nbsp; &nbsp;
              {" "}
              <h2 style={{fontSize:'25px'}}>{currency.currencySymbol +
                parseFloat(topBid).toFixed(2).toString()} USD</h2>
          </>
        )}
        
        &nbsp; &nbsp;

        <p>
        Time Remaining
      </p>
      <Countdown date={new Date(bidEnd * 1000)} daysInHours={false} />
      {product.rating && product.rating > 0 ? (
        <div className="pro-details-rating-wrap">
          <div className="pro-details-rating">
            <Rating ratingValue={product.rating} />
          </div>
        </div>
      ) : (
        ""
      )}
      </div>*/}

      <div className="pro-details-list">
        <div>
          <Toaster />
        </div>

        <ul style={{ listStyle: 'disc', 'paddingLeft': '45px',  'paddingTop': '20px' }}>
        {product && product.bullets && product.bullets.map(bullet => 
            (<li>{bullet}</li> )
        )}
        <li>{`You will receive a free pass for every ${product.minBack} of your backing.`} </li>
        </ul>

      </div>

      {product.variation ? (
        <div className="pro-details-size-color">
          <div className="pro-details-color-wrap">
            <span>Color</span>
            <div className="pro-details-color-content">
              {product.variation.map((single, key) => {
                return (
                  <label
                    className={`pro-details-color-content--single ${single.color}`}
                    key={key}
                  >
                    <input
                      type="radio"
                      value={single.color}
                      name="product-color"
                      checked={
                        single.color === selectedProductColor ? "checked" : ""
                      }
                      onChange={() => {
                        setSelectedProductColor(single.color);
                        setSelectedProductSize(single.size[0].name);
                        setProductStock(1);
                        setQuantityCount(1);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                );
              })}
            </div>
          </div>
          <div className="pro-details-size">
            <div>
              <Toaster />
            </div>

            <span>Size</span>
            <div className="pro-details-size-content">
              {product.variation &&
                product.variation.map((single) => {
                  return single.color === selectedProductColor
                    ? single.size.map((singleSize, key) => {
                        return (
                          <label
                            className={`pro-details-size-content--single`}
                            key={key}
                          >
                            <input
                              type="radio"
                              value={singleSize.name}
                              checked={
                                singleSize.name === selectedProductSize
                                  ? "checked"
                                  : ""
                              }
                              onChange={() => {
                                setSelectedProductSize(singleSize.name);
                                setProductStock(1);
                                setQuantityCount(1);
                              }}
                            />
                            <span className="size-name">{singleSize.name}</span>
                          </label>
                        );
                      })
                    : "";
                })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {product.category ? (
        <div className="pro-details-meta">
          <span>Categories :</span>
          <ul>
            {/*product.category.map((single, key) => {
              return (
                <li key={key}>
                  <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                    {single}
                  </Link>
                </li>
              );
            })*/}
          </ul>
        </div>
      ) : (
        ""
      )}
      {product.tag ? (
        <div className="pro-details-meta">
          <span>Tags :</span>
          <ul>
            {product.tag.map((single, key) => {
              return (
                <li key={key}>
                  <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                    {single}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
          &nbsp;&nbsp;&nbsp;&nbsp;


          <p> <b>Additional Offerings </b> <br/> You will not be charged for any additional offerings you request unless the show happens. </p>
          <br>

          </br>
        <Card className='grayToGray' sx={{ minWidth: 275, borderRadius: '10%'}}>
        <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image="https://firebasestorage.googleapis.com/v0/b/idol-dc7f4.appspot.com/o/Screen%20Shot%202022-11-13%20at%202.32.42%20PM.png?alt=media&token=b4e4de76-38e5-4956-9af2-56f728d348ce"
      />
      
      <CardContent>
        <Typography variant="h5" component="div">
          Sponsor the Show
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {sponsorSalaRequest}
        </Typography>

      </CardContent>
      <CardActions>
        <Button onClick={() => window.location.href = '/idol/sala-los-alamos'} size="small">Sponsor Now ($250)</Button>
      </CardActions>
    </Card>


    <Card className='grayToGray' sx={{ minWidth: 275, borderRadius: '10%', marginTop: '50px'}}>
    
    <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image="https://firebasestorage.googleapis.com/v0/b/idol-dc7f4.appspot.com/o/Happy_Birthday_Typography_1.jpg?alt=media&token=85a93c45-ddcc-4bf4-9b41-02a0fe7319f6"
      />

      <CardContent>
      

        <Typography variant="h5" component="div">
          Birthday Shoutout
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {birthdayRequest}
        </Typography>

      </CardContent>
      <CardActions>
        <Button onClick={() => window.location.href = '/idol/sala-los-alamos'} size="small">Shoutout Now ($25)</Button>
      </CardActions>
    </Card>


    <Card className='grayToGray' sx={{ minWidth: 275, borderRadius: '10%', marginTop: '50px'}}>
    
      <CardContent>
      

        <Typography variant="h5" component="div">
          Custom Request
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {customRequest}
        </Typography>

      </CardContent>
      <CardActions>
        <Button onClick={() => window.location.href = '/idol/sala-los-alamos'} size="small">Submit Request</Button>
      </CardActions>
    </Card>
        

      <div className="pro-details-social">
        <ul>
          <li>
            <a href='/fan-terms'>
              Fan User Agreement
            </a>
          </li>
        </ul>
        <em> By using Idol, you agree to the Fan User Agreement. Remember, a reservation is a binding contract. By placing a reservation on an Idol event, you're committing to buy it if you win.  </em>
      </div>
    </div>
  );
};

ProductDescriptionInfo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  addToast: PropTypes.func,
  cartItems: PropTypes.array,
  compareItem: PropTypes.array,
  currency: PropTypes.object,
  discountedPrice: PropTypes.number,
  finalDiscountedPrice: PropTypes.number,
  finalProductPrice: PropTypes.number,
  product: PropTypes.object,
  wishlistItem: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    },
  };
};

export default connect(null, mapDispatchToProps)(ProductDescriptionInfo);