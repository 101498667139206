import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import ProductModal from "./ProductModal";
import Countdown from "react-countdown";

const ProductGridSingle = ({
  product,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();
  const cur = new Date();
  const secs = cur.getTime();
  const diff = 0;
  const differ = new Date(diff);
  ////console.log(product.bidEnd.seconds);
  return (
    <Fragment>
      <div
        className={`col-xl-3 col-md-6 col-lg-4 col-sm-6 ${
          sliderClassName ? sliderClassName : ""
        }`}
      >
        <div
          className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}
        >
          <div className="product-img">
            <Link to={process.env.PUBLIC_URL + "/events/" + product.id}>
              <img
              style={{'borderRadius': '5%', "objectFit": "cover"}}
                className="default-img"
                width="300"
                height="300"
                src={product.image && product.image[1]}
                alt=""
              />
            </Link>


            <div className="product-details-content">
              <div className="pro-same-action pro-cart">
                <p>{product.celebName}</p>
                <p style={{color: "gray"}} onClick={()=>{}}>
                  <b>Top Bid: ${parseFloat(product.topBid).toFixed(2).toString()}</b>
                </p>
                <b>
                <p style={{color: "gray"}} onClick={()=>{}}>
                  Time Left
                </p>

                <Countdown date={new Date(10000000 * 1000)} daysInHours={false}/>
                </b>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        cartitem={cartItem}
        wishlistitem={wishlistItem}
        compareitem={compareItem}
        addtocart={addToCart}
        addtowishlist={addToWishlist}
        addtocompare={addToCompare}
        addtoast={addToast}
      />
    </Fragment>
  );
};

ProductGridSingle.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default ProductGridSingle;
